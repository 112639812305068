import React, { useState, useEffect } from 'react';
import PptxGenJS from 'pptxgenjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Importing CSS for additional styling

const App = () => {
  const [markdown, setMarkdown] = useState('');
  const [slides, setSlides] = useState([]);
  const [logs, setLogs] = useState([]); // State for storing console logs

  // Capture console logs and update state
  useEffect(() => {
    const originalConsoleLog = console.log;
    console.log = (...args) => {
      setLogs(prevLogs => [...prevLogs, args.map(arg => 
        typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg
      ).join(' ')]);
      originalConsoleLog.apply(console, args);
    };
    return () => {
      console.log = originalConsoleLog;
    };
  }, []);

  // Handles changes to the markdown input
  const handleMarkdownChange = (event) => {
    setMarkdown(event.target.value);
  };

  // Parses the markdown input and extracts slide data
  const parseMarkdown = (markdown) => {
    const lines = markdown.split('\n');
    const slideData = [];
    let currentSlide = null;

    lines.forEach(line => {
      line = line.trim();
      if (line.startsWith('### Slide')) {
        if (currentSlide) {
          slideData.push(currentSlide);
        }
        currentSlide = { Title: '', Content: [] };
      } else if (line.startsWith('* **Title:**')) {
        if (currentSlide) {
          currentSlide.Title = line.replace('* **Title:**', '').trim();
        }
      } else if (line.startsWith('* **Content:**')) {
        // Skip this line, as it is not the content itself
      } else if (line && currentSlide) {
        currentSlide.Content.push(line);
      }
    });

    if (currentSlide) {
      slideData.push(currentSlide);
    }

    setSlides(slideData);
  };

  // Converts the parsed slide data to a PowerPoint presentation
  const convertToPPT = () => {
    console.log("Slides Data:", slides); // Debugging line
    const pptx = new PptxGenJS();

    slides.forEach(slideData => {
      const slide = pptx.addSlide();
      const title = slideData.Title;
      const contentLines = slideData.Content;

      // Set the title
      slide.addText(title, { x: 1, y: 0.5, fontSize: 24, bold: true, color: '000000' });

      // Combine content lines into a single subtitle
      const subtitle = contentLines.join('\n');

      // Set the subtitle
      slide.addText(subtitle, { x: 1, y: 1.5, fontSize: 18, color: '555555' });
    });

    pptx.writeFile('presentation.pptx');
  };

  // Handles the conversion process
  const handleConvert = () => {
    parseMarkdown(markdown);
    convertToPPT();
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Markdown to PowerPoint Converter</h1>
      <h1 className="text-center mb-4">One Time Bugger</h1>
      <div className="form-group">
        <textarea
          className="form-control"
          rows="10"
          value={markdown}
          onChange={handleMarkdownChange}
          placeholder="Enter your markdown here... The Structure of the slide in this format
No Bold Headings and Separator like --- or anything like that. Make it Clean
e.g. 

### Slide 1: Future of Computers
* **Title:** Future of Computers
* **Content:**
      Explore emerging trends and technologies in the computer field (AI, IoT, quantum computing)
      Discuss potential impacts on society and the future of work"
        />
      </div>
      <button className="btn btn-primary mt-3" onClick={handleConvert}>Convert to PowerPoint</button>
      <div className="mt-4">
        <h3>Server Response</h3>
        <pre>{logs.join('\n')}</pre>

      </div>
    </div>
  );
};

export default App;
