import React, { useState } from 'react';
import { marked } from 'marked';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './App.css'; // Ensure this file exists if you use custom styles

function MarkdownViewer() {
  const [html, setHtml] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const markdownText = e.target.result;
        setHtml(marked(markdownText, { renderer: new marked.Renderer(), highlight: (code, lang) => SyntaxHighlighter.highlight(lang, code, solarizedlight) }));
      };
      reader.readAsText(file);
    }
  };

  const downloadHTML = () => {
    const blob = new Blob([html], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'converted.html';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="container mt-5">
      <input type="file" accept=".md" onChange={handleFileChange} />
      <button
        className="btn btn-primary mt-3"
        onClick={downloadHTML}
        disabled={!html}
      >
        Download as HTML
      </button>
      <div
        className="mt-4"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
}

export default MarkdownViewer;
