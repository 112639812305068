import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
const App = () => {
  return (
    <div className="App">
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/">Harkawal Services</a>
      </nav>
      
    <div className="container mt-5">
      
      <h1 className="text-center mb-4">Markdown Viewer</h1>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/withtext">With Text and File</Link></li>
            <li><Link to="/withfile">With File</Link></li>
            <li><Link to="/convert-to-ppt">Convert to PPT</Link></li>
        </ul>
      </nav>
      {/* Other content */}
      </div>    </div>
  );
};

export default App;
