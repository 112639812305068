import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import MarkdownViewer from './MarkdownViewer2';

function App() {
  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="/">Markdown Viewer</a>
      </nav>
      <div className="container">
        <h1 className="text-center mb-4">Markdown Viewer</h1>
        <MarkdownViewer />
      </div>
    </div>
  );
}

export default App;
