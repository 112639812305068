// src/index.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import App1 from './App1';
import App2 from './App2';
import MarkdownToPPT from './MarkdownToPPT';
import './index.css'; // Ensure this file exists if you use custom styles

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/withtext" element={<App1 />} />
      <Route path="/withfile" element={<App2 />} />
      <Route path="/convert-to-ppt" element={<MarkdownToPPT />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
