import React, { useState } from 'react';
import { marked } from 'marked';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

function MarkdownViewer() {
  const [markdown, setMarkdown] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setMarkdown(reader.result);
      reader.readAsText(file);
    }
  };

  const renderer = new marked.Renderer();
  renderer.code = (code, language) => {
    return `<pre><code class="language-${language}">${code}</code></pre>`;
  };

  marked.setOptions({
    renderer: renderer,
    highlight: (code, language) => {
      return SyntaxHighlighter.highlight(code, { language, style: solarizedlight });
    },
  });

  return (
    <div className="row">
      <div className="col-md-6">
        <h3>Upload Markdown File</h3>
        <input type="file" accept=".md" onChange={handleFileChange} />
        <textarea
          className="form-control mt-3"
          rows="10"
          value={markdown}
          onChange={(e) => setMarkdown(e.target.value)}
        />
      </div>
      <div className="col-md-6">
        <h3>Preview</h3>
        <div
          className="border p-3"
          dangerouslySetInnerHTML={{ __html: marked(markdown) }}
        ></div>
      </div>
    </div>
  );
}

export default MarkdownViewer;
